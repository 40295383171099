import "primeicons/primeicons.css";

import React, { useState, useRef } from "react";
import backgroundImage from "./assets/images/event.jpg"
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import { InputMask } from "primereact/inputmask";
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";

function Devis() {
  const toast = useRef(null);
  const [checked, setChecked] = useState(false);
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [phone, setPhone] = useState(22177000000);
  const [title, setTitle] = useState("");
  const [describe, setDescribe] = useState("")
  const [dateStart, setDateStart] = useState('');
  const [dateEnd, setDateEnd] = useState('');
  const [timeStart, setTimeStart] = useState('');
  const [timeEnd, setTimeEnd] = useState('');
  const [capacity, setCapacity] = useState('')
  const [ingredients, setIngredients] = useState([]);

  const [selectPlace, setSelectedPlace] = useState(null);
    const event_place = [
      { name: 'Extérieur', code: 'OUT' },
      { name: 'Intérieur', code: 'IN' },
    ];

  const onUpload = () => {
    toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
  };

  const onIngredientsChange = (e) => {
    let _ingredients = [...ingredients];

    if (e.checked)
        _ingredients.push(e.value);
    else
        _ingredients.splice(_ingredients.indexOf(e.value), 1);

    setIngredients(_ingredients);
}


  return (
    <main className="block-main flex h-screen bg-slate- bg-gradient-to-tl from-gray-200  to-gray-300">
      <div className="flex-1 bg-cover bg-center p-10 text-white" style={{ backgroundImage : `url(${backgroundImage})`}}>
        <h1 className="text-6xl font-bold text-white">Demander un devis</h1>
        <p className="text-xl mt-10 leading-8">Lorem ipsum, dolor sit amet consectetur 
          <span className="text-indigo-800 text-2xl font-bold"> iconoa</span> adipisicing elit. Veniam, omnis. Aliquid, eligendi 
          natus distinctio sint voluptas veritatis <span className="text-indigo-800 text-2xl font-bold"> events</span> minima ex ducimus itaque illum! Reiciendis repellendus 
          <span className="text-indigo-800 text-2xl font-bold"> sénegal</span> ullam nisi sequi blanditiis ex praesentium.</p>
      </div>
      <div className="flex-1 bg-white m-10 px-5 py-10 rounded-xl overflow-y-auto">

        <div className="div-bloc mb-10">
            <h2 className="text-2xl font-bold mb-4 uppercase">Informations de contact</h2>
            <div>
                <div className="flex py-6 mb-2">
                  <div className="card flex justify-content-center mr-4">
                    <FloatLabel>
                      <InputText className="p-2 shadow-lg border" id="username" value={fname} onChange={(e) => setFname(e.target.value)} />
                      <label htmlFor="username">Nom du client</label>
                    </FloatLabel>
                  </div>

                  <div className="card flex justify-content-center">
                    <FloatLabel>
                      <InputText className="p-2 shadow-lg border" id="username" value={lname} onChange={(e) => setLname(e.target.value)} />
                      <label htmlFor="username">Prénom du client</label>
                    </FloatLabel>
                  </div>
                </div>

                <div className="flex mb-2">
                  <div className="card flex justify-content-center mr-4">
                    <FloatLabel>
                      <InputText className="p-2 shadow-lg border" id="username" value={fname} onChange={(e) => setFname(e.target.value)} />
                      <label htmlFor="username">Adresse e-mail</label>
                    </FloatLabel>
                  </div>

                  <div className="card flex justify-content-center">
                    <FloatLabel className="bg-white">
                      <InputMask id="phone" className="p-2" value={phone} onChange={(e) => setPhone(e.target.value)} mask="(999) 99-999-99-99"></InputMask>
                      <label htmlFor="number-input">Numéro de téléphone</label>
                    </FloatLabel>
                  </div>

                </div>
            </div>
        </div>

        <div className="div-bloc">
            <h2 className="text-2xl font-bold mb-4 uppercase">Détails de l'événement</h2>
            <div>
              <div className="flex flex-wrap py-6 mb-2">
                <div className="card flex justify-content-center w-full">
                  <FloatLabel className="w-full">
                    <InputText className="p-2 shadow-lg border w-full" id="title" value={title} onChange={(e) => setTitle(e.target.value)} />
                    <label htmlFor="title">Nom de l'événement</label>
                  </FloatLabel>
                </div>

                <div className="card flex justify-content-center w-full mt-8">
                  <FloatLabel className="w-full">
                    <InputTextarea className="p-2 shadow-lg border w-full resize-none" id="describe" autoResize value={describe} onChange={(e) => setDescribe(e.target.value)} rows={5} />
                    <label htmlFor="describe">Description de l'événement</label>
                  </FloatLabel>
                </div>

                <div className="card flex flex-wrap gap-3 p-fluid mt-8">
                  
                  <div className="card flex justify-content-center mr-4">
                    <FloatLabel className="bg-white">
                      <Calendar id="time" className="p-2 shadow-lg border" value={dateStart} onChange={(e) => setDateStart(e.value)} showIcon />
                      <label htmlFor="username">Date de début</label>
                    </FloatLabel>
                  </div>

                  <div className="card flex justify-content-center mr-4">
                    <FloatLabel className="bg-white">
                      <Calendar id="time" className="p-2 shadow-lg border" value={dateEnd} onChange={(e) => setDateEnd(e.value)} showIcon />
                      <label htmlFor="username">Date de fin</label>
                    </FloatLabel>
                  </div>

                  <div className="card flex justify-content-center mr-4">
                    <FloatLabel className="bg-white">
                      <Calendar id="time" className="p-2 shadow-lg border" value={timeStart} onChange={(e) => setTimeStart(e.value)} showIcon timeOnly  icon={() => <i className="pi pi-clock" />} />
                      <label htmlFor="username">Heure de début</label>
                    </FloatLabel>
                  </div>

                  <div className="card flex justify-content-center mt-4">
                    <FloatLabel className="bg-white">
                      <Calendar id="time" className="p-2 shadow-lg border" value={timeEnd} onChange={(e) => setTimeEnd(e.value)} showIcon timeOnly  icon={() => <i className="pi pi-clock" />} />
                      <label htmlFor="username">Heure de fin</label>
                    </FloatLabel>
                  </div>

                </div>

              </div>
            </div>
        </div>

        <div className="div-bloc">
            <h2 className="text-2xl font-bold mb-4 uppercase">Lieu de l'évenement</h2>
            <div className="">
              <div className="flex flex-wrap pt-6 pb-0 mb-2">
                <div className="card flex justify-content-center mb-8 mr-4">
                  <FloatLabel>
                    <InputText className="p-2 shadow-lg border" id="username" value={fname} onChange={(e) => setFname(e.target.value)} />
                    <label htmlFor="username">Ville</label>
                  </FloatLabel>
                </div>

                <div className="card flex justify-content-center mb-8 mr-4">
                  <FloatLabel>
                    <InputText className="p-2 shadow-lg border" id="username" value={lname} onChange={(e) => setLname(e.target.value)} />
                    <label htmlFor="username">Rue</label>
                  </FloatLabel>
                </div>

                <div className="card flex justify-content-center w-full mb-8 mr-4">
                  <FloatLabel className="w-full">
                    <InputText className="p-2 shadow-lg border w-full" id="username" value={lname} onChange={(e) => setLname(e.target.value)} />
                    <label htmlFor="username">Adresse complète du lieu</label>
                  </FloatLabel>
                </div>

                <div className="card flex justify-content-center mb-8 mr-4">
                  <FloatLabel>
                    <InputText className="p-2 shadow-lg border" id="username" value={lname} onChange={(e) => setLname(e.target.value)} />
                    <label htmlFor="username">Latitude</label>
                  </FloatLabel>
                </div>

                <div className="card flex justify-content-center mb-8 mr-4">
                  <FloatLabel>
                    <InputText className="p-2 shadow-lg border" id="username" value={lname} onChange={(e) => setLname(e.target.value)} />
                    <label htmlFor="username">Longitude</label>
                  </FloatLabel>
                </div>

                <div className="card flex justify-content-center mb-8 mr-4">
                  <FloatLabel className="bg-white">
                    <InputNumber className="p-2" inputId="minmax-buttons" value={capacity} onValueChange={(e) => setCapacity(e.target.value)} mode="decimal" showButtons min={0} max={100} />
                    <label htmlFor="minmax-buttons">Capacité du lieu</label>
                  </FloatLabel>
                </div>

                <div className="card flex justify-content-center mb-8 mr-4">
                  <Dropdown value={selectPlace} onChange={(e) => setSelectedPlace(e.value)} options={event_place} optionLabel="name" 
                    placeholder="Type de lieu" className="w-full md:w-14rem" />
                </div>

              </div>
            </div>
        </div>

        <div className="div-bloc">
            <h2 className="text-2xl font-bold mb-4 uppercase">Nombre d'invités</h2>
            <div className="py-4 mb-2">
              
              <div className="card flex justify-content-center mb-4 mr-4">
                <InputSwitch id="ticket" checked={checked} onChange={(e) => setChecked(e.value)} />
                <label htmlFor="ticket" onChange={(e) => setChecked(e.value)} className="mx-2" >Ticketing</label>
              </div>
              
              <div className="flex">
                <div className="card flex justify-content-center">
                  <Toast ref={toast}></Toast>
                  <FileUpload mode="basic" name="demo[]" url="/api/upload" accept="image/*" maxFileSize={1000000} onUpload={onUpload} />
                </div>

                <div className="card flex justify-content-center ml-4">
                  <FloatLabel className="bg-white">
                    <InputNumber className="p-2" inputId="minmax-buttons" value={capacity} onValueChange={(e) => setCapacity(e.target.value)} mode="decimal" showButtons min={0} max={100} />
                    <label htmlFor="minmax-buttons">Capacité du lieu</label>
                  </FloatLabel>
                </div>
              </div>

            </div>
        </div>

        <div className="div-bloc">
            <h2 className="text-2xl font-bold mb-2 uppercase">Services requis</h2>
            <div className="py-4">
              <div className="card flex flex-wrap justify-content-center gap-3">
                <div className="flex align-items-center">
                    <Checkbox inputId="ingredient1" name="pizza" value="Cheese" onChange={onIngredientsChange} checked={ingredients.includes('Cheese')} />
                    <label htmlFor="ingredient1" className="ml-2">Décoration</label>
                </div>
                <div className="flex align-items-center">
                    <Checkbox inputId="ingredient2" name="pizza" value="Mushroom" onChange={onIngredientsChange} checked={ingredients.includes('Mushroom')} />
                    <label htmlFor="ingredient2" className="ml-2">Photographie</label>
                </div>
                <div className="flex align-items-center">
                    <Checkbox inputId="ingredient3" name="pizza" value="Pepper" onChange={onIngredientsChange} checked={ingredients.includes('Pepper')} />
                    <label htmlFor="ingredient3" className="ml-2">Vidéographie</label>
                </div>
                <div className="flex align-items-center">
                    <Checkbox inputId="ingredient4" name="pizza" value="Onion" onChange={onIngredientsChange} checked={ingredients.includes('Onion')} />
                    <label htmlFor="ingredient4" className="ml-2">Sonorisation</label>
                </div>
                <div className="flex align-items-center">
                    <Checkbox inputId="ingredient4" name="pizza" value="Onion" onChange={onIngredientsChange} checked={ingredients.includes('Onion')} />
                    <label htmlFor="ingredient4" className="ml-2">Eclairage</label>
                </div>
                <div className="flex align-items-center">
                    <Checkbox inputId="ingredient4" name="pizza" value="Onion" onChange={onIngredientsChange} checked={ingredients.includes('Onion')} />
                    <label htmlFor="ingredient4" className="ml-2">Projecteur</label>
                </div>
              </div>
            </div>
        </div>

        <div className="py-10">
          <Button label="Envoyer" icon="pi pi-save" className="px-4 py-2 bg-indigo-700 text-white" size="small" />
        </div>

      </div>
    </main>
  );
}

export default Devis;
