// import { Link } from "react-router-dom";
import backgroundImage from "./assets/images/event.jpg"

const data = {
  event_name: "Forum de l'intelligence artificielle en Afrique",
  event_time: "09h00",
  event_time_end: "17h00",
  event_date: "12/12/2024",
  event_date_end: "14/12/2024",
  event_place: "Avenue Cheikh ahmadou bamba"
}

function App() {
  return (
    <main className="block-main h-screen flex items-center justify-center">
      <a href="/devis">devis</a>
      { [1,2,3,4,5].map((item, index) => 
        <div key={index} className="shadow-2xl flex flex-col w-[20rem] mb-10 mx-4">
        <div className="h-[15rem] bg-cover bg-center" style={{ backgroundImage : `url(${backgroundImage})`}}></div>
        
        <div className="w-full mt-4">
          <h1 className="text-2xl text-center font-bold text-orange-600 mb-4">{data.event_name}</h1>
          <div className="px-4 text-center">
            <p className="text-whit"><i className="pi pi-map"/> {data.event_place}</p>
            <p className=""><i className="pi pi-calendar"/> Du 
              <span className="bg-lime-500 rounded-sm font-semibold mx-2">{data.event_date}</span>  
              au
              <span className="bg-orange-500 rounded-sm font-semibold mx-2">{data.event_date_end}</span>  
            </p>
            <p className=""><i className="pi pi-clock"/> De 
              <span className="bg-lime-500 rounded-sm font-semibold mx-2">{data.event_time}</span>  
              à 
              <span className="bg-orange-600 rounded-sm font-semibold mx-2">{data.event_time_end}</span>  
            </p>
            <p className=""><i className="pi pi-sort-numeric-down"/> Nombre de jour(s) 3</p>
            <p className="font-semibold"><i className="pi pi-phone"/> 33 000 00 00</p>
          </div>
        </div>
        
        <div className="w-[15rem bg-green- p-5">
         <img src={`data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAXIAAAFyAQAAAADAX2ykAAACdklEQVR4nO2bzW3cMBBG30QCcpSAFOBSqA5SUuCS3IFUijsQjwtQ+HIgudbaCRwDsrI0hgcCWr7DBwyG88c18ZG1fPsQDs4777zzzjvv/N94K6uHZQSb2MymaAaxnk0n6nH+YD5IklYgPJtB7NHjQwLoJEm65T9bj/MH87F4qNnYCQbJfq2dym/Wn63H+WP4/tW3lmkzwdaLOCLiuXqc/2Q+rF2+i81GsOl/63H+EH6QNAMsD4kcf2com5TO1uP8MTz75IlO2X/3Wz0LkjTfm37n31m6XUCXN82D3iy3b5O8mfW5CCasm2lmM2AzFuuB6Plz23xQYheEGSSI3wVsBsPF+xtt8vV+XksulY2stdttGfT42yJ/jb+pNrGU0DwkYCg+XWzu9m2XX0aA2GMTnWzKV3OnWiltdq4e54/lc1djSLCMm+WqKG+6WDX8iXqcP4jPibGFGXJDcvl5PYs/kogjFrTVBPre9Dv/zipxdUi7JLpuKTvxrhL2+NsYX/tXg/RyK9dM+mY+6Plzi/wufy5uukKpirLNu6t3u33b44v/vvSay6S/1r8zNwdu38b4a/ytZe5MCbj1tAZmt2+LfM2fn/tEeDIIAgMD6JJdQZ2jx/lj+T/Mjyjl8NVrYUjVz+9Nv/P/wu/eT5as+bpimR/ZdKIe5w/mQx0y5KnR8nAxPY61pxX8/UarfO1LxRHCEwCbidhj0CeWHIE7fx/7Nfg6OpJguFhx3ejv278IbxOd8jgpPPfocQTcf5vla3+jdjUISrwaAudPz59b5N+8n8yPKG9m/jXJcvu2x+f86uVP3tp/lltZu5/uTb/zzjvvvPPOt8j/BjmQ+J3hlBQEAAAAAElFTkSuQmCC`} alt="QR Code" />
        </div>
        </div>
      )}
    </main>
  );
}

export default App;
