import React from 'react';
import { router } from './routers';
import ReactDOM from 'react-dom/client';
import { RouterProvider} from "react-router-dom";
import { PrimeReactProvider } from 'primereact/api';

import "./assets/styles/index.css";
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';


const options = { appendTo: null, styleContainer: null};

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <PrimeReactProvider value={options}>
      <RouterProvider router={router} />
    </PrimeReactProvider>
  </React.StrictMode>


);
