import { createBrowserRouter} from "react-router-dom";

import App from "./App";
import Devis from "./Devis";

export const router = createBrowserRouter([
        { 
                path : "/",
                element : <App />,
        },
        { 
                path : "/devis",
                element : <Devis />,
        },
]);